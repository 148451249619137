import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
  deleteoutlets as deleteOutletApi,
  listoutlets as listoutltsApi,
  listoutletsById as listOutletsByIdApi,
  listoutletsByHotel as listoutletsByHotelApi,
  addOutlets as addOutletsApi,
  createOutletUsers as createOutletUsersApi
  // logout as logoutApi,
  // signup as signupApi,
  // forgotPassword as forgotPasswordApi,
} from "../../helpers/api/outlet";

// constants
import { OutletsActionTypes } from "./constants";
// actions
import { apiOutletsResponseError, apiOutletsResponseSucces } from "./actions";
import { toast } from "react-toastify";


function* listOutlets({payload: searchParams,
  type}: any): SagaIterator{
  try{
    console.log('the response', searchParams)
    const response = yield call(listoutltsApi, searchParams);
 
    yield put(apiOutletsResponseSucces(OutletsActionTypes.LIST_OUTLETS, response.data.result))
  }
  catch(err: any){
    yield put(apiOutletsResponseError(OutletsActionTypes.LIST_OUTLETS, err))
  }
}

function* createHotels({payload: searchParams,
  type}: any): SagaIterator{
  try{
    console.log('the response', searchParams)
    const response = yield call(addOutletsApi, searchParams);
    toast.success(response.data.info.msg);
    yield put(apiOutletsResponseSucces(OutletsActionTypes.CREATE_OUTLETS, response.data.result.data))
  }
  catch(err: any){
    toast.error(err.response.data.error);
    yield put(apiOutletsResponseError(OutletsActionTypes.CREATE_OUTLETS, err))
  }
}
function* searcbyHotel({payload: searchParams,
  type}: any): SagaIterator{
  try{
    console.log('the response', searchParams)
    const response = yield call(listoutletsByHotelApi, searchParams);
 
    yield put(apiOutletsResponseSucces(OutletsActionTypes.SEARCH_OUTLETS_BY_HOTELS, response.data.result))
  }
  catch(err: any){
    yield put(apiOutletsResponseError(OutletsActionTypes.SEARCH_OUTLETS_BY_HOTELS, err))
  }
}

function* listOutletsById({
  payload: details,
  type,
}: any): SagaIterator{
  try{
    console.log('theresp, response', details.id)
    const repsonse = yield call(listOutletsByIdApi, details.id);
    
    yield put(apiOutletsResponseSucces(OutletsActionTypes.EDIT_OUTLETS_SELECTED, repsonse.data.result[0]))
  }
  catch(err:any){
    yield put(apiOutletsResponseError(OutletsActionTypes.EDIT_OUTLETS_SELECTED, err));
 
  }
}
function* deleteOutlet({
  id,
  type,
}: any): SagaIterator{
  try{
    console.log("the payel", id)
    const repsonse = yield call(deleteOutletApi, id);
    toast.success(repsonse.data.info.msg);
    if(repsonse) yield call(listOutlets,{page:0,limit:5})
    yield put(apiOutletsResponseSucces(OutletsActionTypes.DELET_OUTLETS,{}))
  }
  catch(err:any){
    toast.error(err.response.data.error);
    yield put(apiOutletsResponseError(OutletsActionTypes.DELET_OUTLETS, err));
 
  }
}


function* createOutletUsers({
  details,
  type,
}: any): SagaIterator{
  try{
    console.log('the payload', details)
    const repsonse = yield call(createOutletUsersApi, details);
    toast.success(repsonse.data.info.msg);
    yield put(apiOutletsResponseSucces(OutletsActionTypes.ADD_OUTLET_USERS,{}))
  }
  catch(err:any){
    toast.error(err.response.data.error);
    yield put(apiOutletsResponseError(OutletsActionTypes.ADD_OUTLET_USERS, err));
 
  }
}
function* resetValues({
  type,
  payload,
}: any): SagaIterator{
  try{
    
    yield put(apiOutletsResponseSucces(OutletsActionTypes.RESET_OUTLETS,{}))
  }
  catch(err:any){
    yield put(apiOutletsResponseError(OutletsActionTypes.RESET_OUTLETS, err));
 
  }
}


export function* watchListingOutlets() {
  yield takeEvery(OutletsActionTypes.LIST_OUTLETS, listOutlets);
}
export function* watchListSupportById(): any {
  yield takeEvery(OutletsActionTypes.EDIT_OUTLETS_SELECTED, listOutletsById);
}
export function* watchDeleteOutlet(): any {
  yield takeEvery(OutletsActionTypes.DELET_OUTLETS, deleteOutlet);
}

export function* watchResetSelectedOutlet(): any {
  yield takeEvery(OutletsActionTypes.RESET_OUTLETS, resetValues);
}

export function* watchSearchByHotels(): any{
  yield takeEvery(OutletsActionTypes.SEARCH_OUTLETS_BY_HOTELS, searcbyHotel);
}

export function* watchCreateOutlets(): any{
  yield takeEvery(OutletsActionTypes.CREATE_OUTLETS, createHotels);
}

export function* watchCreateOutletUsers(): any{
  yield takeEvery(OutletsActionTypes.ADD_OUTLET_USERS, createOutletUsers);
}

function* outletSaga() {
  yield all([
    fork(watchListingOutlets),
    fork(watchListSupportById),
    fork(watchDeleteOutlet),
    fork(watchResetSelectedOutlet),
    fork(watchSearchByHotels),
    fork(watchCreateOutlets),
    fork(watchCreateOutletUsers)
    // fork(watchLogout),
    // fork(watchSignup),
    // fork(watchForgotPassword),
  ]);
}

export default outletSaga;
